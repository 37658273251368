import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

// core components

function SectionOverview() {
  const coreValues = [
    {
      title: "Innovation",
      description:
        "We are committed to driving technological advancements and delivering cutting-edge solutions that keep your business ahead of the curve.",
      color: "#115EA8"
    },
    {
      title: "Integrity",
      description:
        "We conduct our business with the highest level of honesty and transparency, ensuring trust and reliability in every interaction.",
      color: "#ADAF1E"
    },
    {
      title: "Client Focus",
      description:
        "Our clients are at the heart of everything we do. We listen, understand, and tailor our services to meet their unique needs and exceed expectations.",
      color: "#DA8235"
    },
    {
      title: "Collaboration",
      description:
        "We believe in the power of teamwork and foster a collaborative environment where ideas are shared, and partnerships are forged to achieve mutual success.",
      color: "#0D938C"
    }
  ];
  return (
    <>
      <div className="section section-overview">
        <Container fluid>
          <Row>
            <Col className="offset-md-2 text-center" md="8">
              <div className="space-top" />
              <h2 className="title" style={{ fontWeight: "500" }}>
                Our Core Values
              </h2>
            </Col>
            <Container>
              <div className="space-top" />
              <Row>
                {coreValues.map(({ title, description, color }, i) => {
                  return (
                    <Col sm="3" key={i}>
                      <Card style={{ backgroundColor: color, height: "17rem" }}>
                        <CardBody
                          className="text-center"
                          style={{ color: "#ffff" }}
                        >
                          <CardTitle
                            tag="h4"
                            className="mt-3"
                            style={{ color: "#ffff", fontWeight: "600" }}
                          >
                            {title}
                          </CardTitle>
                          <p style={{fontSize: "16px", marginTop: "0.5rem"}}>{description}</p>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionOverview;
