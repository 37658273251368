/*eslint-disable*/
import React, { useEffect, useState } from "react";

// reactstrap components
import { Container } from "reactstrap";
import Background from "assets/img/background.jpg";
// core comments

function PresentationHeader() {
  const { innerWidth } = window;
  const [windowWidth, setWindowWidth] = useState(innerWidth);
  useEffect(() => {
    function handleWindowResize() {
      const { innerWidth } = window;
      setWindowWidth(innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
  }, [windowWidth]);
  console.log(windowWidth);
  return (
    <>
      <div className="wrapper">
        <div
          className="page-header"
          style={{ marginTop: "5rem", height: "100%", alignItems: "center" }}
        >
          <div
            className="content-center header-background"
            style={{ height: "100%", backgroundColor: "blue" }}
          >
            <Container
              style={{
                textAlign: "center",
                marginTop: "15rem"
              }}
            >
              <div className="title-brand">
                <h1
                  className="presentation-title"
                  style={{ fontSize: windowWidth < 551 ? "3rem" : "auto" }}
                >
                  Bizsolutions
                </h1>
                {/* <div className="fog-low">
                  <img
                    alt="..."
                    src={require("assets/img/sections/fog-low.png")}
                  />
                </div>
                <div className="fog-low right">
                  <img
                    alt="..."
                    src={require("assets/img/sections/fog-low.png")}
                  />
                </div> */}
              </div>
              <h2 className="presentation-subtitle text-center">
                Leading the Way in IT Innovation. Elevate Your Business with
                Bizsolutions
              </h2>
            </Container>
          </div>
          <h6 className="category category-absolute">
            Designed and coded by{" "}
            <a
              href="https://www.creative-tim.com?ref=pkpr-presentation-header"
              target="_blank"
            >
              <img
                alt="..."
                className="creative-tim-logo"
                src={require("assets/img/creative-tim-white-slim2.png")}
              />
            </a>
          </h6>
        </div>
      </div>
    </>
  );
}

export default PresentationHeader;
