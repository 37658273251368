import React from "react";
import "assets/css/custom-style.css";
import { Icon } from "@iconify/react";
import { colors } from "assets/colors";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
const FeatureCard = ({ title, description, icon, path }) => {
  const navigate = useNavigate();
  return (
    <div className="large-hover-card">
      <div class="inner-wrap relative">
        <div style={{ height: "100px" }} className="card-icon">
          <Icon icon={icon} width={100} color={colors.primary.main} />
        </div>
        <h2 style={{ fontWeight: "600", fontSize: "1.8rem" }}>{title}</h2>
        <div
          class="summary-wrap"
          style={{ color: "black", textAlign: "left", marginTop: "0.5rem" }}
        >
          <p>{description}</p>
          <Button
            onClick={() => {
              navigate(path);
            }}
            style={{
              backgroundColor: colors.primary.main,
              border: "1px solid white"
            }}
          >
            View Detail
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
