/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "assets/img/Bizsolution.jpeg";

// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";
import { colors } from "assets/colors";

// core components

function FooterBlack() {
  const { innerWidth } = window;
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(innerWidth);
  const Icons = [
    {
      icon: "fa-twitter",
      name: "Twitter",
      id: "twitter",
      redirect: "https://www.linkedin.com/company/diligentpharma/"
    },
    {
      icon: "fa-facebook-square",
      name: "Facebook",
      id: "facebook",
      redirect: "https://vimeo.com/diligentpharma"
    },
    {
      icon: "fa-brands fa-linkedin",
      name: "Linkedin",
      id: "linkedin",
      redirect: "https://youtube.com/@diligentpharma7131?si=nE0oJpOAUzIUhPDy"
    },
    // {
    //   icon: "fa-whatsapp",
    //   name: "Whatsapp",
    //   id: "whatsapp",
    //   redirect: "https://youtube.com/@diligentpharma7131?si=nE0oJpOAUzIUhPDy"
    // }
  ];
  useEffect(() => {
    function handleWindowResize() {
      const { innerWidth } = window;
      setWindowWidth(innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
  }, [windowWidth]);
  return (
    <>
      <section
        className="main-footer pl-4 pr-4"
        style={{
          overflowX: "hidden",
          backgroundColor: colors.primary.secondary
        }}
      >
        <Row
          className="mt-5 mb-4"
          style={{
            justifyContent: "center",
            textAlign: "center"
          }}
        >
          <Col>
            <div>
              <h2
                style={{
                  fontSize: "3rem",
                  color: colors.primary.main,
                  fontWeight: "600"
                }}
              >
                Bizsolutions
              </h2>
              <p style={{ fontSize: "1rem", color: "#ffff" }}>
                Driving Excellence Through Audit Modernization
              </p>
            </div>
          </Col>
          {/* <Col
            lg={1}
            md={2}
            xs={5}
            sm={3}
            style={{ marginRight: windowWidth < 978 ? "0.5rem" : "2rem" }}
          >
            <div>
              <h2
                style={{
                  fontSize: "1rem",
                  color: "#ffff",
                  fontWeight: "600",
                  marginBottom: "0.5rem"
                }}
              >
                Product
              </h2>

              <p
                style={{
                  fontSize: "0.65rem",
                  cursor: "pointer",
                  color: "#EFEFE9"
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Overview
              </p>
            </div>
          </Col>
          <Col
            lg={1}
            md={2}
            xs={5}
            sm={3}
            style={{ marginRight: windowWidth < 978 ? "0.5rem" : "2rem" }}
          >
            {" "}
            <div>
              <h2
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  color: "#ffff",
                  marginBottom: "0.5rem"
                }}
              >
                Resources
              </h2>

              <p
                style={{
                  fontSize: "0.65rem",
                  cursor: "pointer",
                  color: "#EFEFE9"
                }}
                onClick={() => {
                  navigate("/faqs");
                }}
              >
                FAQ
              </p>
              <p
                style={{
                  fontSize: "0.65rem",
                  cursor: "pointer",
                  color: "#EFEFE9"
                }}
                onClick={() => {
                  window.location.href = "https://vimeo.com/diligentpharma";
                }}
              >
                Vimeo
              </p>
              <p
                style={{
                  fontSize: "0.65rem",
                  cursor: "pointer",
                  color: "#EFEFE9"
                }}
                onClick={() => {
                  window.location.href =
                    "https://youtube.com/@diligentpharma7131?si=nE0oJpOAUzIUhPDy";
                }}
              >
                Youtube
              </p>
            </div>
          </Col>
          <Col
            lg={1}
            md={2}
            xs={5}
            sm={3}
            //style={{ marginRight: windowWidth < 978 ? "0.5rem" : "2rem" }}
          >
            {" "}
            <div>
              <h2
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  color: "#ffff",
                  marginBottom: "0.5rem"
                }}
              >
                Social
              </h2>

              <p
                style={{
                  fontSize: "0.65rem",
                  cursor: "pointer",
                  color: "#EFEFE9"
                }}
                onClick={() => {
                  window.location.href =
                    "https://www.linkedin.com/company/diligentpharma/";
                }}
              >
                LinkedIn
              </p>

              <p
                style={{
                  fontSize: "0.65rem",
                  cursor: "pointer",
                  color: "#EFEFE9"
                }}
                onClick={() => {
                  window.location.href =
                    "https://www.diligentpharma.com/contact-us/";
                }}
              >
                Contact
              </p>
            </div>
          </Col>
          <Col
            lg={1}
            md={2}
            xs={5}
            sm={3}
            // style={{ marginRight: windowWidth < 978 ? "0.5rem" : "2rem" }}
          >
            <div>
              <h2
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  color: "#ffff",
                  marginBottom: "0.5rem"
                }}
              >
                Legal
              </h2>
              <p
                style={{
                  fontSize: "0.65rem",
                  cursor: "pointer",
                  color: "#EFEFE9"
                }}
                onClick={() => {
                  window.location.href =
                    "https://www.diligentpharma.com/privacy/";
                }}
              >
                Privacy
              </p>
              <p
                style={{
                  fontSize: "0.65rem",
                  cursor: "pointer",
                  color: "#EFEFE9"
                }}
                onClick={() => {
                  window.location.href =
                    "https://www.diligentpharma.com/cookies/";
                }}
              >
                Cookies
              </p>
            </div>
          </Col> */}
        </Row>
        <Row
          style={{
            paddingLeft: windowWidth > 1002 && "1.5rem",
            paddingBottom: "0.8rem",
            justifyContent: "center"
          }}
        >
          <Col lg={4} md={4}>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              {Icons.map((item, index) => {
                return (
                  <div>
                    <Button
                      // className="btn-round mr-1"
                      color={item.id}
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                        borderRadius: "8rem",
                        width: "45px",
                        height: "45px"
                      }}
                      id={item.id}
                    >
                      <i
                        className={`fa ${item.icon}`}
                        style={{ fontSize: "1.5rem", marginLeft: "-0.5rem" }}
                      />
                      {/* {item.name} */}
                    </Button>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </section>
      <Row
        style={{
          backgroundColor: "#101010",
          padding: "1rem",
          textAlign: "center",
          justifyContent: "center",
          marginRight: "0px",
          marginLeft: "0px"
        }}
      >
        © {new Date().getFullYear()} Bizsolutions. All rights reserved.
      </Row>
    </>
  );
}

export default FooterBlack;
