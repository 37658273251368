import { colors } from "assets/colors";
import React from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionSharing() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="section"
        onClick={()=> {
          navigate("/contact-us")
        }}
        style={{
          padding: "3rem",
          backgroundColor: "rgb(243, 241, 241)",
          cursor: "pointer",
        }}
        id="demoPay"
      >
        <Container
          style={{
            backgroundImage: "url(" + require(`assets/img/background.jpeg`) + ")",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",   backgroundImage: "url(" + require(`assets/img/background.jpeg`) + ")",
            backgroundColor: colors.primary.main,
            padding: "2rem",
            marginBottom: "1rem",
            borderRadius: "0.5rem"
          }}
        >
          <Row
            style={{
              width: "100%",
              alignItems: "center",
              marginRight: "0px",
              marginLeft: "0px"
            }}
          >
            {/* <Col lg={3} md={4} xs={6}>
              <Button
                className="btn-round"
                color={colors.primary.main}
                onClick={() => {
                  navigate("/contact-us");
                }}
                style={{
                  backgroundColor: colors.primary.secondary,
                  color: "#ffff",
                  padding: "1rem",
                  width: "15rem",
                  border: "none",
                  fontSize: "0.8rem",
                  fontWeight: "600"
                }}
                target="_blank"
              >
                Talk to our Experts
              </Button>
            </Col> */}
            <Col xs={12}>
              <div style={{ width: "100%", textAlign: "center" }}>
                <h3
                  className="title"
                  style={{ textTransform: "uppercase", fontWeight: "500" }}
                >
                  Looking for tailored solutions for your business?
                </h3>
                <h3
                  className="title"
                  style={{
                    color: "#8c9a23",
                    fontWeight: "500",
                    textTransform: "lowercase",
                    marginTop: "-0.8rem"
                  }}
                >
                  Connect with us today!
                </h3>
              </div>
            </Col>
          </Row>
          <br />

          {/* <Col className="ml-auto mr-auto" md="8">
              <div className="space-top" />
              <Row>
                <Col md="6">
                  <Card className="card-pricing">
                    <CardBody>
                      <div className="card-icon">
                        <span className="icon-simple">
                          <i className="nc-icon nc-single-02" />
                        </span>
                      </div>
                      <CardTitle tag="h3">$79</CardTitle>
                      <p className="card-description">
                        For personal websites, school projects, startups or any
                        other project where your users will not be charged for
                        using it. Create one single website/app for one client.
                      </p>
                      <CardFooter>
                        <Button
                          className="btn-round"
                          target="_blank"
                          color="danger"
                          href="https://www.creative-tim.com/product/paper-kit-pro-react?ref=pkpr-presentation-page"
                        >
                          Buy Now
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className="card-pricing">
                    <CardBody>
                      <div className="card-icon">
                        <span className="icon-simple">
                          <i className="nc-icon nc-bank" />
                        </span>
                      </div>
                      <CardTitle tag="h3">$319</CardTitle>
                      <p className="card-description">
                        For business projects, multiple websites for clients or
                        for creating themes where your users will be charged for
                        using it. Create multiple websites/apps for multiple
                        clients.
                      </p>
                      <CardFooter>
                        <Button
                          className="btn-round"
                          target="_blank"
                          color="danger"
                          href="https://www.creative-tim.com/product/paper-kit-pro-react?ref=pkpr-presentation-page"
                        >
                          Buy Now
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col> */}
          {/* <div className="text-center">
            <span>Pay one time with:</span>
            <i className="fa fa-cc-paypal fa-pay mr-1" />
            <i className="fa fa-cc-visa fa-pay mr-1" />
            <i className="fa fa-cc-mastercard fa-pay mr-1" />
            <i className="fa fa-cc-amex fa-pay" />
            <br />
            <br />
          </div>
          <div className="title text-center">
            <h3>Thank you for sharing!</h3>
            <br />
            <Button className="btn-round mr-1" color="twitter" id="twitter">
              <i className="fa fa-twitter mr-1" />
              Twitter
            </Button>
            <Button className="btn-round mr-1" color="facebook" id="facebook">
              <i className="fa fa-facebook-square mr-1" />
              Facebook
            </Button>
            <Button className="btn-round" color="google" id="google">
              <i className="fa fa-google-plus mr-1" />
              Google
            </Button>
          </div> */}
        </Container>
      </div>
    </>
  );
}

export default SectionSharing;
