import FeatureCard from "components/Cards/feature-cards";
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components

function SectionSummary() {
  const serviceList = [
    {
      title: "IT Services",
      description:
        "Bizsolutions provides comprehensive IT services, including web development, mobile app creation, and DevOps solutions. We deliver tailored technology solutions to drive your business growth and efficiency.",
      icon: "carbon:ibm-cloud-internet-services",
      path: "/it-services"
    },
    {
      title: "Accounting Services",
      description:
        "Bizsolutions offers expert accounting services, including streamlined accounts preparation, strategic management accounts, and specialized forensic accounting.",
      icon: "mdi:view-grid-plus-outline",
      path: "/about-us"
    },
    {
      title: "Other Business Solution",
      description:
        "Bizsolutions delivers a range of business solutions, including financial record keeping, business launch guidance, and payroll management.",
      icon: "ion:business",
      path: "/about-us"
    }
  ];
  return (
    <>
      <div className="section section-dark section-summary">
        <Container>
          <Row>
            {serviceList.map(({ title, description, icon, path }) => {
              return (
                <Col
                  md="4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <FeatureCard
                    title={title}
                    description={description}
                    icon={icon}
                    path={path}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionSummary;
