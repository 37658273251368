import React, { useEffect, useState } from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import CustomToast from "components/custom-toast";
// reactstrap components
import { Button, Form, Input, Container, Row, Col, Label } from "reactstrap";

// core components
import InfoNavbar from "components/Navbars/InfoNavbar.js";
import FooterWhite from "components/Footers/FooterWhite.js";
import FooterBlack from "components/Footers/FooterBlack";
import ColorNavbar from "components/Navbars/ColorNavbar";
import { colors } from "assets/colors";
import AccountingHeader from "components/Headers/AboutUsHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react";
import axios from "axios";
import { API } from "constant";
import { CONTACT_US } from "constant";

const MapWrapper = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 44.445248, lng: 26.099672 }}
      defaultOptions={{
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#e9e9e9"
              },
              {
                lightness: 17
              }
            ]
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              },
              {
                lightness: 20
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 17
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 29
              },
              {
                weight: 0.2
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 18
              }
            ]
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 16
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              },
              {
                lightness: 21
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#dedede"
              },
              {
                lightness: 21
              }
            ]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on"
              },
              {
                color: "#ffffff"
              },
              {
                lightness: 16
              }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                saturation: 36
              },
              {
                color: "#333333"
              },
              {
                lightness: 40
              }
            ]
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#f2f2f2"
              },
              {
                lightness: 19
              }
            ]
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#fefefe"
              },
              {
                lightness: 20
              }
            ]
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#fefefe"
              },
              {
                lightness: 17
              },
              {
                weight: 1.2
              }
            ]
          }
        ],
        scrollwheel: false //we disable de scroll over the map, it is a really annoing when you scroll through page
      }}
    >
      <Marker position={{ lat: 44.445248, lng: 26.099672 }} />
    </GoogleMap>
  ))
);

function ContactUs() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  const [toast, setToast] = useState({
    message: "",
    open: false,
    type: "error"
  });
  const CustomBox = ({ heading, line1, line2, icon }) => {
    return (
      <Col style={{ display: "flex", flexDirection: "column", color: "black" }}>
        <div
          style={{
            display: "flex"
          }}
        >
          <Icon
            icon={icon}
            width={"50px"}
            style={{ marginRight: "0.3rem", color: colors.primary.main }}
          />
          <h3
            style={{
              marginTop: "0.5rem",
              fontSize: "1.2rem",
              fontWeight: "600"
            }}
          >
            {heading}
          </h3>
        </div>
        <label style={{ marginLeft: "3.5rem", marginTop: "0.3rem" }}>
          {line1}
        </label>
        <label style={{ marginLeft: "3.5rem", marginTop: "0.1rem" }}>
          {line2}
        </label>
      </Col>
    );
  };
  const [fields, setFields] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: ""
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const InputFields = [
    {
      name: "fullName",
      label: "Full Name *",
      type: "string",
      md: 12
    },
    {
      name: "email",
      label: "Email *",
      type: "email",
      md: 12
    },
    {
      name: "subject",
      label: "Subject",
      type: "string",
      md: 12
    },
    {
      name: "message",
      label: "Message *",
      type: "textarea",
      rows: 7,
      md: 12
    }
  ];
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!fields.fullName || !fields.message || !fields.email) {
      setError("Please complete all required fields");
    } else {
      setError("");
      if (!/\S+@\S+\.\S+/.test(fields.email)) {
        return setError("Please enter a valid email address");
      } else {
        setError("");
        try {
          const response = await axios.post(`${API}${CONTACT_US}`, fields);
          if (response) {
            const res = JSON.parse(response.data.body);
            setFields({
              fullName: "",
              message: "",
              subject: "",
              email: ""
            });
            setToast({
              open: true,
              type: "success",
              message: res.title
            });
          }
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };
  return (
    <>
      <ColorNavbar />
      <AccountingHeader
        image={"contact-us.jpeg"}
        heading={"Contact Us"}
        // detail={
        //   "Unlock the potential of your business with Bizsolutions' IT-driven accounting expertise."
        // }
      />
      <div className="main">
        <div className="section">
          <Row
            style={{
              marginLeft: "0px",
              marginRight: "0px"
            }}
          >
            <Col md={8} xs={12}>
              <Col
                className="ml-auto mr-auto text-center"
                md="6"
                xs={"12"}
                style={{
                  marginTop: "-3rem",
                  padding: "2rem"
                }}
              >
                <h3
                  style={{
                    fontWeight: "600",
                    marginBottom: "0.5rem",
                    color: "black"
                  }}
                >
                  {" "}
                  Send us a message
                </h3>
                <Label style={{ color: "black" }}>
                  You can contact us with anything related to our Products.
                  We'll get in touch with you as soon as possible.
                </Label>
                {error && (
                  <p style={{ fontWeight: "600", color: "red" }}>{error}</p>
                )}
                <Form className="contact" onSubmit={onSubmitHandler}>
                  <Row>
                    {InputFields.map((item) => {
                      return (
                        <Col md={item.md}>
                          <Input
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                [item.name]: e.target.value
                              });
                            }}
                            placeholder={item.label}
                            value={fields[item.name]}
                            type={item.type}
                            rows={item.rows}
                            style={{
                              border: "none",
                              borderBottom: `1px solid ${colors.primary.main}`,
                              borderRadius: "0px"
                            }}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                  {/* <Input
                    placeholder="First Name"
                    type="text"
                    style={{
                      border: "none",
                      borderBottom: `1px solid ${colors.primary.main}`,
                      borderRadius: "0px"
                    }}
                  />
                  <Input
                    placeholder="Email"
                    type="text"
                    style={{
                      border: "none",
                      borderBottom: `1px solid ${colors.primary.main}`,
                      borderRadius: "0px"
                    }}
                  />
                  <Input
                    placeholder="Subject"
                    type="text"
                    style={{
                      border: "none",
                      borderBottom: `1px solid ${colors.primary.main}`,
                      borderRadius: "0px"
                    }}
                  />
                  <Input
                    placeholder="Message"
                    rows="7"
                    type="textarea"
                    style={{
                      border: "none",
                      borderBottom: `1px solid ${colors.primary.main}`,
                      borderRadius: "0px"
                    }}
                  /> */}
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <Button
                        block
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
              md={4}
              xs={12}
            >
              <CustomBox
                icon={"ion:location-outline"}
                heading={"Find us at the office"}
                line1={"Bld Mihail Kogalniceanu,"}
                line2={"nr. 87652 Bucharest,Romania"}
              />

              <CustomBox
                icon={"bi:phone"}
                heading={"Give us a ring"}
                line1={"Michael Jordan +40 762 321 762"}
                line2={"Mon - Fri, 8:00-22:00"}
              />

              <CustomBox
                icon={"ri:information-line"}
                heading={"Company Detail"}
                line1={"linkedin profile"}
                line2={"abc@email.com"}
              />
            </Col>
          </Row>
        </div>
      </div>
      <CustomToast
        open={toast.open}
        message={toast.message}
        type={toast.type}
        setOpen={setToast}
      />
      <FooterBlack />
    </>
  );
}

export default ContactUs;
