import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import Bizsolution from "assets/img/Bizsolution.jpeg";
import { Navigate } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";
import { colors } from "assets/colors";
// core components

function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const navigate = useNavigate();

  const navItems = [
    {
      name: "Home",
      link: "/"
    },
    {
      name: "Accounting Services",
      link: "/about-us"
    },
    {
      name: "IT Services",
      link: "/it-services"
    },
    {
      name: "Contact Us",
      link: "/contact-us"
    }
  ];
  // eslint-disable-next-line no-restricted-globals
  const pathname = location.pathname;
  const { innerWidth } = window;
  const [windowWidth, setWindowWidth] = useState(innerWidth);
  useEffect(() => {
    function handleWindowResize() {
      const { innerWidth } = window;
      setWindowWidth(innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
  }, [windowWidth]);
  return (
    <>
      5
      <Navbar className="fixed-top" expand="lg" id="navbar-main">
        <Container
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            maxWidth: "1200px"
          }}
        >
          <div className="navbar-translate">
            <NavbarBrand
              id="navbar-brand"
              style={{ padding: "0px" }}
              to="/"
              tag={Link}
            >
              <img
                src={Bizsolution}
                alt="bizSolution"
                style={{ width: "130px", objectFit: "cover" }}
              />
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              Bizsolutions
            </UncontrolledTooltip>
            {bodyClick ? (
              <button
                className="navbar-toggler"
                id="navigation"
                type="button"
                onClick={() => {
                  //document.documentElement.classList.toggle("nav-open");
                  setBodyClick(false);
                  setCollapseOpen(false);
                }}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            ) : (
              <button
                className="navbar-toggler"
                id="navigation"
                type="button"
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setBodyClick(true);
                  setCollapseOpen(true);
                }}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            )}
          </div>
          <Collapse
            navbar
            isOpen={collapseOpen}
            style={{
              visibility:
                windowWidth < 992
                  ? collapseOpen
                    ? "visible"
                    : "hidden"
                  : "visible",
              backgroundColor: "white"
            }}
          >
            <Nav
              className="ml-auto"
              navbar
              style={{
                alignItems: "center",
                fontSize: "1rem",
                fontWeight: "500",
                color: colors.primary.secondary,
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                flexDirection: "row",
                flexWrap: "wrap"
              }}
            >
              {navItems.map((item, index) => {
                return index === 3 ? (
                  <NavItem>
                    <Button
                      className="btn-round"
                      color={colors.primary.main}
                      style={{
                        backgroundColor:
                          windowWidth < 992 && pathname === "/contact-us"
                            ? "black"
                            : colors.primary.main,
                        border: "none",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        borderRadius: windowWidth < 992 && "0.4rem",
                        width: windowWidth < 992 && "10rem",
                        // marginLeft: windowWidth < 992 && "0.7rem",
                        padding: windowWidth < 992 && "0.99rem"
                      }}
                      target="_blank"
                      onClick={() => {
                        navigate("/contact-us");
                      }}
                    >
                      Contact Us
                    </Button>
                  </NavItem>
                ) : (
                  <NavItem
                    className="mr-3"
                    color="default"
                    caret
                    nav
                    key={item.name}
                    style={{
                      cursor: "pointer",
                      color: "white",
                      width: windowWidth < 992 && "10rem",
                      borderRadius: "0.4rem",
                      textAlign: "center",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      // marginLeft: windowWidth < 992 && "0.2rem",
                      // marginTop: windowWidth < 992 && "0.2rem",
                      textTransform: "uppercase",
                      // marginBottom:
                      //   windowWidth < 992 && (index === 0 || index === 1)
                      //     ? "-2rem"
                      //     : "0px",
                      padding:
                        windowWidth < 992 && (index === 0 || index === 2)
                          ? "0.99rem"
                          : windowWidth < 992 && index === 1
                          ? "0.4rem"
                          : "auto",
                      backgroundColor:
                        item.link === pathname && windowWidth < 992
                          ? "black"
                          : windowWidth < 992 && colors.primary.main,
                      cursor: "pointer",
                      color:
                        item.link === pathname && windowWidth > 992
                          ? colors.primary.main
                          : windowWidth < 992
                          ? "white"
                          : "black"
                    }}
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    {item.name}
                  </NavItem>
                );
              })}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;
