import FooterBlack from "components/Footers/FooterBlack";
import AccountingHeader from "components/Headers/AboutUsHeader";
import ColorNavbar from "components/Navbars/ColorNavbar";
import CustomCard from "components/custom-cards";
import React from "react";
import { Container, Row } from "reactstrap";

const ITServices = () => {
  const itService = [
    {
      title: "Web Development",
      description:
        "Build your online presence with our innovative web development services. We create visually appealing, responsive, and user-friendly websites that drive engagement and growth."
    },
    {
      title: "Mobile App Development",
      description:
        "Reach your audience on the go with our cutting-edge mobile app development. We design and develop high-quality apps for iOS and Android, ensuring a seamless user experience and robust performance."
    },
    {
      title: "DevOps and Cloud Solutions",
      description:
        "Optimize your operations with our DevOps and cloud solutions. Our experienced team ensures smooth integration, continuous delivery, and efficient cloud management, enhancing your business agility and scalability."
    },
    {
      title: "IT Infrastructure Management",
      description:
        "Ensure the reliability and security of your IT systems with our comprehensive infrastructure management services. We provide monitoring, maintenance, and support to keep your operations running smoothly."
    },
    {
      title: "Data Analytics and Business Intelligence",
      description:
        "Unlock the power of your data with our analytics and business intelligence services. We provide actionable insights to help you make data-driven decisions and stay ahead of the competition."
    }
  ];
  return (
    <>
      <ColorNavbar />
      <AccountingHeader
        image={"IT_solutions.jpg"}
        heading={"IT SERVICES"}
        detail={
          "Empower your business with Bizsolutions' innovative IT solutions, driving growth and efficiency through technology."
        }
      />
      <div className="main" style={{ color: "#000" }}>
        <div className="section">
          <Container
            style={{
              paddingRight: "0px",
              paddingLeft: "0px",
              maxWidth: "1200px"
            }}
          >
            <h3
              className="title-uppercase text-center"
              style={{ fontWeight: "600" }}
            >
              Comprehensive IT Solutions
            </h3>
            <div className="mt-4">
              <Row style={{ marginRight: "0px", marginLeft: "0px" }}>
                {" "}
                {itService.map((item) => {
                  return (
                    <CustomCard
                      key={item.name}
                      title={item.title}
                      description={item.description}
                    />
                  );
                })}
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
};

export default ITServices;
