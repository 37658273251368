import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components

function AccountingHeader({ image, heading, detail }) {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage: "url(" + require(`assets/img/${image}`) + ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="filter filter-primary" />
        <div className="content-center">
          <Container>
            <h1 style={{ fontWeight: "600" }}>{heading}</h1>
            <h3 style={{ fontWeight: "500" }}>{detail}</h3>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AccountingHeader;
